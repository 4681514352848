import type { FC } from 'react';
import type { Theme } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { getSmallSVGLogoURL } from '../utils/subappThemeHelper';

interface LogoProps {
  sx?: SxProps<Theme>;
  absolute?: boolean;
}

const LogoRoot = experimentalStyled('img')(({ theme, sx, ...otherProps }) => ({
  width: 52,
  height: 52,
  display: 'flex',
  margin: 'auto',
  paddingTop: 3,
  paddingLeft: 5,
  paddingRight: 5,
  filter: 'drop-shadow(1px 1px 1px #222)',
  backgroundColor: 'none',
  borderRadius: 'unset',
  // @ts-ignore
  marginTop: 'unset',
}));

const Logo: FC<LogoProps> = (props) => <LogoRoot src={getSmallSVGLogoURL()} {...props} />;

export default Logo;
